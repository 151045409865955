import { useState, useEffect } from 'react';
// @mui
import { MenuItem, Stack } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
// hooks
import useLocales from '../../../hooks/useLocales';

// components
import Image from '../../../components/Image';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import Iconify from '../../../components/Iconify';


// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const theme = useTheme();
  const chatSettings=[{
    'id':1,
    'icon':"ic:chat",
    'label':"Text Chat",
    'value':'text-chat',
    },
    {
      'id':2,
      'icon':"material-symbols:voice-chat",
      'label':"Voice (Auto)",
      'value':'voice-auto-send',
      },
      {
        'id':3,
        'icon':"material-symbols:voice-chat",
        'label':"Voice (Manual)",
        'value':'voice-manual-send',
        }];
  const { currentLang, onChangeLang } = useLocales();
  
  const [open, setOpen] = useState(null);
  const [selectedSetting, setSelectedSetting] = useState(localStorage.getItem('chat-options')?JSON.parse(localStorage.getItem('chat-options')):chatSettings[0]);
  const [change, setChange] = useState(false);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const onChangeSettings =(e)=>{
    setSelectedSetting(e);
  }
  useEffect(() => {
    localStorage.setItem('chat-options',JSON.stringify(selectedSetting));
  },[selectedSetting])


  
  return (
    
      <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          width: 42,
          height: 42,
          ...(!open && { bgcolor:  theme.palette.mode === 'light'?'grey.300':'grey.800' }),
        }}
      >
        
        <Iconify icon={selectedSetting.icon} width={20} height={20} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {chatSettings.map((option) => (
            <MenuItem
              key={option.id}
             
              selected={option.value === selectedSetting.value}
              onClick={() => {
                onChangeSettings(option);
                handleClose();
              }}
            > 
              <Iconify icon={option.icon} width={20} height={20} />
              &nbsp;
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
   </> 
    
  );
}
