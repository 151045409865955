// @mui
import { Stack, Button, Typography } from '@mui/material';
import { Link as RouterLink, useNavigate  } from 'react-router-dom';
// hooks
import useAuth from '../../../hooks/useAuth';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// assets
import { DocIllustration } from '../../../assets';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleManageAccount=()=>{
    navigate(PATH_DASHBOARD.user.account);
  }
  return (
    <Stack
      spacing={3}
      sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}
    >
      <DocIllustration sx={{ width: 1 }} />

      <div>
        <Typography gutterBottom variant="subtitle1">
          Hi, {user?.name}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Need to set your preferences?
          <br /> Please click on Manage Account
        </Typography>
      </div>
     
      <Button onClick={handleManageAccount} variant="contained">
        Manage Account
      </Button>
    </Stack>
  );
}
