// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------
import Iconify from '../../../components/Iconify';


const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Virtual Clients', path: PATH_DASHBOARD.general.app, icon: <Iconify icon={'clarity:dashboard-solid-badged'} width={20} height={20} /> },
      { title: 'Session History', path:  PATH_DASHBOARD.client.list, icon: <Iconify icon={'clarity:users-solid'} width={20} height={20} /> },
      { title: 'Transcripts', path: "#", icon: <Iconify icon={'clarity:list-solid'} width={20} height={20} /> },

      
    ],
  },
 
];

export default navConfig;
