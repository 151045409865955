// ----------------------------------------------------------------------

export const fullAddress = [
  '19034 Verna Unions Apt. 164 - Honolulu, RI / 87535',
  '1147 Rohan Drive Suite 819 - Burlington, VT / 82021',
  '18605 Thompson Circle Apt. 086 - Idaho Falls, WV / 50337',
  '110 Lamar Station Apt. 730 - Hagerstown, OK / 49808',
  '36901 Elmer Spurs Apt. 762 - Miramar, DE / 92836',
  '2089 Runolfsson Harbors Suite 886 - Chapel Hill, TX / 32827',
  '279 Karolann Ports Apt. 774 - Prescott Valley, WV / 53905',
  '96607 Claire Square Suite 591 - St. Louis Park, HI / 40802',
  '9388 Auer Station Suite 573 - Honolulu, AK / 98024',
  '47665 Adaline Squares Suite 510 - Blacksburg, NE / 53515',
  '989 Vernice Flats Apt. 183 - Billings, NV / 04147',
  '91020 Wehner Locks Apt. 673 - Albany, WY / 68763',
  '585 Candelario Pass Suite 090 - Columbus, LA / 25376',
  '80988 Renner Crest Apt. 000 - Fargo, VA / 24266',
  '28307 Shayne Pike Suite 523 - North Las Vegas, AZ / 28550',
  '205 Farrell Highway Suite 333 - Rock Hill, OK / 63421',
  '253 Kara Motorway Suite 821 - Manchester, SD / 09331',
  '13663 Kiara Oval Suite 606 - Missoula, AR / 44478',
  '8110 Claire Port Apt. 703 - Anchorage, TN / 01753',
  '4642 Demetris Lane Suite 407 - Edmond, AZ / 60888',
  '74794 Asha Flat Suite 890 - Lancaster, OR / 13466',
  '8135 Keeling Pines Apt. 326 - Alexandria, MA / 89442',
  '441 Gibson Shores Suite 247 - Pasco, NM / 60678',
  '4373 Emelia Valley Suite 596 - Columbia, NM / 42586',
  '1946 Strosin Creek Apt. 279 - Casper, CO / 21335',
  '147 Rempel Center Apt. 305 - Centennial, TN / 09375',
  '78481 Floyd Mountains Apt. 184 - Glendale, SD / 18777',
  '129 Toy Pines Suite 339 - Idaho Falls, CT / 82070',
  '08945 Cremin Underpass Apt. 700 - Iowa City, GA / 33243',
  '20728 Dorcas Highway Suite 959 - Largo, ND / 99931',
  '492 Wilburn Park Apt. 746 - Bellevue, TN / 16077',
  '70530 Garfield Tunnel Suite 902 - Detroit, IA / 01664',
  '868 Kautzer Grove Suite 334 - Kearny, KS / 51368',
  '71189 Towne Forks Suite 519 - Anchorage, IL / 52636',
  '5070 Magdalena Neck Suite 096 - Cedar Rapids, PA / 01342',
  '6216 Jermain Walk Apt. 279 - Wilmington, MA / 55416',
  '1795 Kilback Stravenue Apt. 745 - Concord, MA / 96099',
  '831 Rolfson Expressway Apt. 878 - Norman, NY / 10239',
  '909 Nathaniel Lake Suite 769 - Sherman, NJ / 46523',
  '3690 Hans Ports Apt. 369 - Cary, KS / 48930'
];

export const country = [
  'Kenya',
  'Madagascar',
  'Netherlands Antilles',
  'Azerbaijan',
  'Aruba',
  'Comoros',
  'Sierra Leone',
  'Bermuda',
  'Italy',
  'Iran',
  'Denmark',
  'Congo',
  'Cambodia',
  'Virgin Islands, British',
  'Bahamas',
  'Italy',
  'France',
  'Portugal',
  'Nepal',
  'Saint Vincent and the Grenadines',
  'Greenland',
  'Maldives',
  'Comoros',
  'Bhutan',
  'Tuvalu',
  'Ethiopia',
  'Myanmar',
  'Libyan Arab Jamahiriya',
  'Senegal',
  'Malta',
  'Kyrgyz Republic',
  'Turks and Caicos Islands',
  'Gibraltar',
  'Sweden',
  'Zambia',
  'Isle of Man',
  'Canada',
  'Turkey',
  'Samoa',
  'Vietnam'
];
